// Initial configuration added on install

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kellokortti-ui-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$kellokortti-ui-accent: mat.m2-define-palette(
	mat.$m2-pink-palette,
	A200,
	A100,
	A400
);

// The warn palette is optional (defaults to red).
$kellokortti-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kellokortti-ui-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $kellokortti-ui-primary,
			accent: $kellokortti-ui-accent,
			warn: $kellokortti-ui-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($kellokortti-ui-theme);

// Overrides & Custom styles

// Table

[mat-row] {
	&:hover {
		background-color: $green-10 !important;
	}

	&:nth-child(odd) {
		background-color: $secondary-10;
	}
}

[mat-header-cell] {
	text-decoration: underline;
}

// Drag and Drop

.cdk-drop-list {
	border: solid 1px $neutral-30;
	min-height: 44px;
	display: block;
	border-radius: 4px;
	overflow: hidden;
}

.cdk-drag {
	padding: 15px 20px;
	border-bottom: solid 1px $neutral-30;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	cursor: move;
	background: white;
	font-size: 1rem;

	&:last-child {
		border: none;
	}
}

.cdk-drag-placeholder {
	background: $neutral-30;
	min-height: 44px;
}

.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	background-color: $green-10;
	box-shadow:
		0 5px 5px -3px rgba(0, 0, 0, 0.2),
		0 8px 10px 1px rgba(0, 0, 0, 0.14),
		0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

//Multiselect overrides

mat-option[aria-selected='true']:not(.mat-option-multiple) {
	background: $blue-80 !important;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
	background-color: $blue-80 !important;
	border-color: white !important;
}

.multiselect-is-selected {
	color: white;
}
// Checkbox
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:checked
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:indeterminate
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control[data-indeterminate='true']:enabled
	~ .mdc-checkbox__background {
	border-color: $green-100 !important;
	background-color: $green-100 !important;
}

.cdk-menu {
	display: inline-flex;
	flex-direction: column;
	min-width: 180px;
	background-color: rgb(255, 255, 255);
	padding: 0;
	border: 1px solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
	.cdk-menu-item {
		border: 0;
		background-color: rgb(255, 255, 255);
		text-align: left;
		padding: 6px 10px;
		&:hover {
			background-color: $neutral-10 !important;
		}
	}
}

kk-root {
	// cdk-menu requires this
	z-index: 0;
	position: relative;
}
.cdk-overlay-container {
	z-index: 99999;
}
.mbsc-popup-wrapper {
	z-index: 1;
}
